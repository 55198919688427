import * as React from "react";
import Layout from "../../components/layout";
import { Col, Row } from "react-bootstrap";

const ImprintPage = () => {
  return (
    <Layout>
      <Row>
        <Col>
          <h1 className="page-title">Contact</h1>
          <p>
            As we try to operate as cost-efficient as possible, we can't provide
            a phone hotline anymore. Please contact us via e-mail (using the
            central contact address below) or contact your personal speech coach
            directly.
          </p>
          <p>
            Please contact us under{" "}
            <a href="mailto:stutter-relief@beauty-foundation.com">
              stutter-relief@beauty-foundation.com
            </a>{" "}
            if you have any questions.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default ImprintPage;
